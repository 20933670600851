import React, { useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import GreenCheckbox from "./GreenCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";
import { api, apiToken, privateKey } from "../../config";

import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";

import "./style.scss";
import { AppContext } from "../../contexts/AppContext";

const SignUpPage = (props) => {
  const [state] = useContext(AppContext);

  const initialState = {
    title: "Mr",
    email: "",
    fullName: "",
    password: "",
    confirm_password: "",
    address: "",
    city: "",
    county: "",
    postcode: "",
    mobile: "",
    membership: "Family",
    spouseTitle: "",
    spouseName: "",
    spouseEmail: "",
    spouseMobile: "",
    childCount: 0,
    childName1: "",
    childYear1: "",
    childName2: "",
    childYear2: "",
    childName3: "",
    childYear3: "",
    childName4: "",
    childYear4: "",
    childName5: "",
    childYear5: "",
    agreeTerms: false,
    giftAidConcent: false,
    residentConcent: false,
    paymentConcent: false,
    childPhotoConcent: false,
    childPhotoShareConcent: false,
  };
  const [value, setValue] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };
  const changeCheckboxHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.checked });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
      messages: {
        accepted: "Please agree to proceed.",
      },
    })
  );

  const submitForm = async (e) => {
    e.preventDefault();
    if (value.membership === "Single") {
      validator.fields = {
        ...validator.fields,
        spouseTitle: true,
        spouseName: true,
        spouseEmail: true,
        spouseMobile: true,
        childPhotoConcent: true,
        childPhotoShareConcent: true,
      };
    }
    if (validator.allValid()) {
      setIsLoading(true);
      const { confirm_password, ...rawDataToPost } = value;
      const dataToPost = {
        ...rawDataToPost,
        email: rawDataToPost.email.toLowerCase(),
      };
      const hashDigest = sha256(value.password);
      const hmacDigest = Base64.stringify(hmacSHA512(hashDigest, privateKey));

      // check email already registered
      const userExistsData = await fetch(`${api}/collections/get/userlist`, {
        headers: {
          "Cockpit-Token": apiToken,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          filter: { email: dataToPost.email },
        }),
      });
      const userExists = await userExistsData.json();
      if (userExists.entries.length > 0) {
        toast.error("Email id already exist!");
        setIsLoading(false);
        return true;
      }

      // get member ID for new member
      const lastUser = await fetch(`${api}/collections/get/userlist`, {
        headers: {
          "Cockpit-Token": apiToken,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          sort: { _created: -1 },
          limit: 1,
        }),
      });
      const members = await lastUser.json();
      const memberId = members.entries[0].memberId;

      const uData = await fetch(`${api}/collections/save/userlist`, {
        headers: {
          "Cockpit-Token": apiToken,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          data: {
            ...dataToPost,
            password: hmacDigest,
            role: "member",
            memberId: parseInt(memberId) + 1,
            lastSubscriptionRenewedYear: new Date().getFullYear(),
          },
        }),
      });
      const user = await uData.json();
      setValue(initialState);
      validator.hideMessages();
      toast.success(
        "Thank you for registering. Your application is under review."
      );
      setIsLoading(false);
      props.history.push("/confirmation");
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };
  return (
    <Grid className="loginWrapper">
      <Grid className="loginForm signupForm">
        {!state?.siteSettings?.disableMembershipReg ? (
          <div>
            <h2>Signup</h2>
            <p>Signup your account</p>
            <form onSubmit={submitForm}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="title-label">Title</InputLabel>
                    <Select
                      className="inputOutline"
                      id="title"
                      labelId="title-label"
                      name="title"
                      value={value.title}
                      label="Title"
                      placeholder="Title"
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    >
                      <MenuItem value="Mr">Mr</MenuItem>
                      <MenuItem value="Mrs">Mrs</MenuItem>
                      <MenuItem value="Miss">Miss</MenuItem>
                      <MenuItem value="Dr">Dr</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    {validator.message("title", value.title, "required")}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="Full Name"
                    value={value.fullName}
                    variant="outlined"
                    name="fullName"
                    label="Name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message("full name", value.fullName, "required")}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="E-mail"
                    value={value.email}
                    variant="outlined"
                    name="email"
                    label="E-mail"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message("email", value.email, "required|email")}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="Password"
                    value={value.password}
                    type="password"
                    variant="outlined"
                    name="password"
                    label="Password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message("password", value.password, "required")}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="Confirm Password"
                    type="password"
                    value={value.confirm_password}
                    variant="outlined"
                    name="confirm_password"
                    label="Confirm Password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message(
                    "confirm password",
                    value.confirm_password,
                    `required|in:${value.password}`
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="Address Line 1"
                    value={value.address}
                    variant="outlined"
                    name="address"
                    label="Address"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message("address", value.address, "required")}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="City"
                    value={value.city}
                    variant="outlined"
                    name="city"
                    label="City"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message("city", value.city, "required")}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="County"
                    value={value.county}
                    variant="outlined"
                    name="county"
                    label="County"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="Post code"
                    value={value.postcode}
                    variant="outlined"
                    name="postcode"
                    label="Post code"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message("postcode", value.postcode, "required")}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="Mobile"
                    value={value.mobile}
                    variant="outlined"
                    name="mobile"
                    label="Mobile"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message(
                    "mobile",
                    value.mobile,
                    "required|numeric|max:11|min:10"
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="membership-label">
                      Membership Type
                    </InputLabel>
                    <Select
                      className="inputOutline"
                      id="membership"
                      labelId="membership-label"
                      name="membership"
                      value={value.membership}
                      label="Membership Type"
                      placeholder="Membership"
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Family">Family</MenuItem>
                      <MenuItem value="Single">Single</MenuItem>
                    </Select>
                    {validator.message(
                      "membership",
                      value.membership,
                      "required"
                    )}
                  </FormControl>
                </Grid>
                {value.membership === "Family" && (
                  <>
                    <Grid item xs={12} className="text-center">
                      <h5>Spouse Information</h5>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="sp-title-label">
                          Spouse Title
                        </InputLabel>
                        <Select
                          className="inputOutline"
                          id="spouseTitle"
                          labelId="sp-title-label"
                          name="spouseTitle"
                          value={value.spouseTitle}
                          label="Spouse Title"
                          placeholder="Title"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                        >
                          <MenuItem value="Mr">Mr</MenuItem>
                          <MenuItem value="Mrs">Mrs</MenuItem>
                          <MenuItem value="Dr">Dr</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        {validator.message(
                          "spouseTitle",
                          value.spouseTitle,
                          "required"
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className="inputOutline"
                        fullWidth
                        placeholder="Spouse Name"
                        value={value.spouseName}
                        variant="outlined"
                        name="spouseName"
                        label="Spouse Name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                      />
                      {validator.message(
                        "spouseName",
                        value.spouseName,
                        "required"
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className="inputOutline"
                        fullWidth
                        placeholder="Spouse E-mail"
                        value={value.spouseEmail}
                        variant="outlined"
                        name="spouseEmail"
                        label="E-mail"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                      />
                      {validator.message(
                        "spouseEmail",
                        value.spouseEmail,
                        "required|email"
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className="inputOutline"
                        fullWidth
                        placeholder="Spouse Mobile"
                        value={value.spouseMobile}
                        variant="outlined"
                        name="spouseMobile"
                        label="Mobile"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                      />
                      {validator.message(
                        "spouseMobile",
                        value.spouseMobile,
                        "required|numeric|max:11|min:10"
                      )}
                    </Grid>
                    <Grid item xs={12} className="text-center">
                      <h5>
                        Children {`  `}
                        <Select
                          variant="outlined"
                          className="inputOutline"
                          name="childCount"
                          value={value.childCount}
                          label="Count"
                          placeholder="childCount"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                        </Select>
                      </h5>
                    </Grid>
                    {Array.apply(null, { length: value.childCount }).map(
                      (e, i) => (
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={8}>
                              <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder={`Child ${i + 1} Name`}
                                value={value[`childName${i + 1}`]}
                                variant="outlined"
                                name={`childName${i + 1}`}
                                label={`Child ${i + 1} Name`}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                              />
                              {validator.message(
                                `childName${i + 1}`,
                                value[`childName${i + 1}`],
                                "required"
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Year"
                                value={value[`childYear${i + 1}`]}
                                variant="outlined"
                                name={`childYear${i + 1}`}
                                label="Year of birth"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                              />
                              {validator.message(
                                `childYear${i + 1}`,
                                value[`childYear${i + 1}`],
                                "required|numeric|max:4"
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </>
                )}
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              name="agreeTerms"
                              checked={value.agreeTerms}
                              onChange={(e) => changeCheckboxHandler(e)}
                            />
                          }
                          label={`I declare that all information contained in this form is true,
                    correct to the best of my knowledge, and accept the rules and
                    regulations of MMA based on the constitution`}
                        />
                        {validator.message(
                          "agreeTerms",
                          value.agreeTerms,
                          "accepted"
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              name="giftAidConcent"
                              checked={value.giftAidConcent}
                              onChange={(e) => changeCheckboxHandler(e)}
                            />
                          }
                          label={`Gift Aid - (Gift Aid is a scheme that allows charities to
                        reclaim tax on a donation made by a UK taxpayer, which means
                        that MMA can claim back from the HMRC 25p for every £1 you
                        donate) I here by declare that all donations I make on or
                        after the date of this declaration to Manchester Malayalee
                        Association are to be treated as gift aid donations.`}
                        />
                        {validator.message(
                          "giftAidConcent",
                          value.giftAidConcent,
                          "accepted"
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              name="residentConcent"
                              checked={value.residentConcent}
                              onChange={(e) => changeCheckboxHandler(e)}
                            />
                          }
                          label={`I confirm that I am a resident of the Greater Manchester area.`}
                        />
                        {validator.message(
                          "residentConcent",
                          value.residentConcent,
                          "accepted"
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              name="paymentConcent"
                              checked={value.paymentConcent}
                              onChange={(e) => changeCheckboxHandler(e)}
                            />
                          }
                          label={`I agree to pay the one-off registration fees of £20 (£40 for family membership) and the £10 (£20 for single) annual subscription fees on acceptance of my application by the Trustees Board.`}
                        />
                        {validator.message(
                          "paymentConcent",
                          value.paymentConcent,
                          "accepted"
                        )}
                      </FormControl>
                    </Grid>
                    {value.membership === "Family" && (
                      <>
                        <Grid item xs={12}>
                          <h4>Child photo / video consent</h4>
                          <FormControl fullWidth variant="outlined">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  name="childPhotoConcent"
                                  checked={value.childPhotoConcent}
                                  onChange={(e) => changeCheckboxHandler(e)}
                                />
                              }
                              label={`I give permission to take photographs and / or video of my
                      child.`}
                            />
                            {validator.message(
                              "childPhotoConcent",
                              value.childPhotoConcent,
                              "accepted"
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  name="childPhotoShareConcent"
                                  checked={value.childPhotoShareConcent}
                                  onChange={(e) => changeCheckboxHandler(e)}
                                />
                              }
                              label={`I grant full rights to use the images resulting from the
                      photography/video filming, and any reproductions or
                      adaptations of the images for fundraising, publicity or other
                      purposes to help achieve MMA’s aims. This might include (but
                      is not limited to), the right to use them in their printed and
                      online publicity, social media, press releases and funding
                      applications.`}
                            />
                            {validator.message(
                              "childPhotoShareConcent",
                              value.childPhotoShareConcent,
                              "accepted"
                            )}
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid className="formFooter">
                    <Button
                      fullWidth
                      className="cBtn cBtnLarge cBtnTheme"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Please wait..." : "Sign Up"}
                    </Button>
                  </Grid>
                  <p className="noteHelp">
                    Already have an account?{" "}
                    <Link to="/login">Return to Sign In</Link>
                  </p>
                </Grid>
              </Grid>
            </form>
          </div>
        ) : (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: state.siteSettings.disableMembershipMessage,
              }}
            />
            <Link to="/">Go back to home</Link>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default withRouter(SignUpPage);
